var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.spaceUsers.length
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap align-center" },
              [
                _vm._l(
                  Object.keys(_vm.userCheckboxBinders),
                  function (item, index) {
                    return _c("v-checkbox", {
                      key: index,
                      staticClass: "mx-4",
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: {
                                          small: "",
                                          color: "secondary",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.userCheckboxBinders[item].icon
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "secondary--text caption text-uppercase",
                                      },
                                      [_vm._v(_vm._s(item))]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.userCheckboxBinders[item].status,
                        callback: function ($$v) {
                          _vm.$set(_vm.userCheckboxBinders[item], "status", $$v)
                        },
                        expression: "userCheckboxBinders[item].status",
                      },
                    })
                  }
                ),
                _c("v-spacer"),
                _c("v-text-field", {
                  staticClass: "mr-2",
                  attrs: {
                    autofocus: "",
                    label: "Filter",
                    solo: "",
                    flat: "",
                    "background-color": "grey lighten-4",
                    dense: "",
                    "hide-details": "",
                    clearable: "",
                    "prepend-inner-icon": "mdi-filter",
                  },
                  model: {
                    value: _vm.memberSearch,
                    callback: function ($$v) {
                      _vm.memberSearch = $$v
                    },
                    expression: "memberSearch",
                  },
                }),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: { text: "", icon: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$store.dispatch(
                                          "spaceStore/fetchSpaceUsers",
                                          _vm.$route.params.sid
                                        )
                                      },
                                    },
                                  },
                                  on
                                ),
                                [_c("v-icon", [_vm._v("refresh")])],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1690712231
                    ),
                  },
                  [_c("span", [_vm._v("refresh")])]
                ),
              ],
              2
            ),
            _c("v-divider", { staticClass: "mb-5" }),
            _c("v-data-iterator", {
              attrs: { items: _vm.filteredUsersByRole },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._l(props.items, function (item, index) {
                          return _c(
                            "div",
                            { key: item.uid },
                            [
                              index > 0
                                ? _c("v-divider", {
                                    staticClass: "mb-2",
                                    staticStyle: {
                                      opacity: "0.25",
                                      "border-width": "thin",
                                    },
                                    attrs: { inset: "" },
                                  })
                                : _vm._e(),
                              _c(
                                "v-list-item",
                                { attrs: { "two-line": "" } },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { align: "center" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "1" } },
                                        [
                                          _c(
                                            "v-list-item-avatar",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { "x-large": "" } },
                                                [_vm._v("person")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "3" } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-medium secondary--text mr-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.full_name)
                                                      ),
                                                    ]
                                                  ),
                                                  item.is_active
                                                    ? _c(
                                                        "v-chip",
                                                        {
                                                          attrs: {
                                                            "x-small": "",
                                                            color: "success",
                                                          },
                                                        },
                                                        [_vm._v("Active")]
                                                      )
                                                    : !item.is_active
                                                    ? _c(
                                                        "v-chip",
                                                        {
                                                          attrs: {
                                                            "x-small": "",
                                                            color: "error",
                                                          },
                                                        },
                                                        [_vm._v("Inactive")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-subtitle", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "caption mr-3",
                                                  },
                                                  [_vm._v(_vm._s(item.email))]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "3" } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-subtitle",
                                                {
                                                  staticStyle: { width: "30%" },
                                                },
                                                [
                                                  _c(
                                                    "ul",
                                                    {
                                                      staticStyle: {
                                                        "list-style-type":
                                                          "none",
                                                      },
                                                    },
                                                    [
                                                      item.space_role ===
                                                        _vm.roleTypes
                                                          .SPACE_ADMIN &&
                                                      _vm.userCheckboxBinders
                                                        .administrators.status
                                                        ? _c(
                                                            "ComponentWithStyledBorder",
                                                            [
                                                              _c("li", [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-space-between align-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex align-center text-truncate",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticClass:
                                                                              "mr-1 ml-1",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "security"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "text-truncate",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.space_name
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "UserActions",
                                                                      {
                                                                        attrs: {
                                                                          uid: item.uid.toString(),
                                                                          ucid: item.ucid.toString(),
                                                                          isActiveUser:
                                                                            item.is_active,
                                                                          userName:
                                                                            item.full_name,
                                                                          hierarchicalLevel:
                                                                            _vm
                                                                              .nuvolosObjectTypes
                                                                              .SPACE,
                                                                          showRevokeButton: true,
                                                                          showDeactivateActivateButton: false,
                                                                          role: "Space Administrator",
                                                                          spaceName:
                                                                            item.space_name,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      item.instance_editor_roles
                                                        .length &&
                                                      _vm.userCheckboxBinders
                                                        .editors.status
                                                        ? _c(
                                                            "div",
                                                            _vm._l(
                                                              item.instance_editor_roles,
                                                              function (role) {
                                                                return _c(
                                                                  "ComponentWithStyledBorder",
                                                                  {
                                                                    key: role.iid,
                                                                  },
                                                                  [
                                                                    _c("li", [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-space-between align-center",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex align-center text-truncate",
                                                                            },
                                                                            [
                                                                              role.role_name ===
                                                                              _vm
                                                                                .roleTypes
                                                                                .INSTANCE_VIEWER
                                                                                ? _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-1 ml-1",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " visibility "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : role.role_name ===
                                                                                  _vm
                                                                                    .roleTypes
                                                                                    .INSTANCE_EDITOR
                                                                                ? _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-1 ml-1",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " edit "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : role.role_name ===
                                                                                  _vm
                                                                                    .roleTypes
                                                                                    .INSTANCE_OBSERVER
                                                                                ? _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-1 ml-1",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " visibility_off "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-truncate",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      role.Instance
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "UserActions",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  uid: item.uid.toString(),
                                                                                  ucid: item.ucid.toString(),
                                                                                  isActiveUser:
                                                                                    item.is_active,
                                                                                  userName:
                                                                                    item.full_name,
                                                                                  hierarchicalLevel:
                                                                                    _vm
                                                                                      .nuvolosObjectTypes
                                                                                      .INSTANCE,
                                                                                  spaceAdmin: false,
                                                                                  showRevokeButton: true,
                                                                                  showDeactivateActivateButton: false,
                                                                                  instanceId:
                                                                                    role.iid.toString(),
                                                                                  instanceName:
                                                                                    role.Instance,
                                                                                  role: role.role_name,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      item.instance_viewer_roles
                                                        .length &&
                                                      _vm.userCheckboxBinders
                                                        .viewers.status
                                                        ? _c(
                                                            "div",
                                                            _vm._l(
                                                              item.instance_viewer_roles,
                                                              function (role) {
                                                                return _c(
                                                                  "ComponentWithStyledBorder",
                                                                  {
                                                                    key: role.iid,
                                                                  },
                                                                  [
                                                                    _c("li", [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-space-between align-center",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex align-center text-truncate",
                                                                            },
                                                                            [
                                                                              role.role_name ===
                                                                              _vm
                                                                                .roleTypes
                                                                                .INSTANCE_VIEWER
                                                                                ? _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-1 ml-1",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " visibility "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : role.role_name ===
                                                                                  _vm
                                                                                    .roleTypes
                                                                                    .INSTANCE_EDITOR
                                                                                ? _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-1 ml-1",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " edit "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-truncate",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      role.Instance
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "UserActions",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  uid: item.uid.toString(),
                                                                                  ucid: item.ucid.toString(),
                                                                                  isActiveUser:
                                                                                    item.is_active,
                                                                                  userName:
                                                                                    item.full_name,
                                                                                  hierarchicalLevel:
                                                                                    _vm
                                                                                      .nuvolosObjectTypes
                                                                                      .INSTANCE,
                                                                                  spaceAdmin: false,
                                                                                  showRevokeButton: true,
                                                                                  showDeactivateActivateButton: false,
                                                                                  instanceId:
                                                                                    role.iid.toString(),
                                                                                  instanceName:
                                                                                    role.Instance,
                                                                                  role: role.role_name,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      item
                                                        .instance_observer_roles
                                                        .length &&
                                                      _vm.userCheckboxBinders
                                                        .observers.status
                                                        ? _c(
                                                            "div",
                                                            _vm._l(
                                                              item.instance_observer_roles,
                                                              function (role) {
                                                                return _c(
                                                                  "ComponentWithStyledBorder",
                                                                  {
                                                                    key: role.iid,
                                                                  },
                                                                  [
                                                                    _c("li", [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-space-between align-center",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex align-center text-truncate",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-1 ml-1",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "visibility_off"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-truncate",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      role.Instance
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "UserActions",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  uid: item.uid.toString(),
                                                                                  ucid: item.ucid.toString(),
                                                                                  isActiveUser:
                                                                                    item.is_active,
                                                                                  userName:
                                                                                    item.full_name,
                                                                                  hierarchicalLevel:
                                                                                    _vm
                                                                                      .nuvolosObjectTypes
                                                                                      .INSTANCE,
                                                                                  spaceAdmin: false,
                                                                                  showRevokeButton: true,
                                                                                  showDeactivateActivateButton: false,
                                                                                  instanceId:
                                                                                    role.iid.toString(),
                                                                                  instanceName:
                                                                                    role.Instance,
                                                                                  role: role.role_name,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c("AssignRolesDialog", {
                                                        attrs: {
                                                          assignee:
                                                            item.full_name,
                                                          instanceData:
                                                            _vm.getTargetInstancesForUserAssign(
                                                              item.uid
                                                            ),
                                                          spaceName:
                                                            item.space_name,
                                                          ucid: item.ucid.toString(),
                                                          spaceRole:
                                                            item.space_role,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-list-item-action", [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        item.space_role !==
                                        _vm.roleTypes.SPACE_ADMIN
                                          ? _c("UserActions", {
                                              attrs: {
                                                uid: item.uid.toString(),
                                                ucid: item.ucid.toString(),
                                                isActiveUser: item.is_active,
                                                userName: item.full_name,
                                                hierarchicalLevel:
                                                  _vm.nuvolosObjectTypes
                                                    .INSTANCE,
                                                spaceAdmin: false,
                                                showRevokeButton: false,
                                              },
                                            })
                                          : item.space_role ===
                                            _vm.roleTypes.SPACE_ADMIN
                                          ? _c("UserActions", {
                                              attrs: {
                                                uid: item.uid.toString(),
                                                ucid: item.ucid.toString(),
                                                isActiveUser: item.is_active,
                                                userName: item.full_name,
                                                hierarchicalLevel:
                                                  _vm.nuvolosObjectTypes.SPACE,
                                                showRevokeButton: false,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        _c("invite-btn", {
                          on: {
                            goToInvite: function ($event) {
                              return _vm.$emit("goToInvite", { value: true })
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "no-results",
                    fn: function () {
                      return [
                        _c(
                          "v-alert",
                          {
                            staticClass: "mt-3",
                            attrs: {
                              value: true,
                              color: "error",
                              icon: "warning",
                              text: "",
                            },
                          },
                          [_vm._v("Your search for found no results.")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                72995509
              ),
            }),
          ],
          1
        )
      : !_vm.spaceUsers.length && !_vm.fetchingSpaceUsers
      ? _c(
          "div",
          [
            _c(
              "v-alert",
              { attrs: { text: "", prominent: "", type: "info" } },
              [
                _c("div", { staticClass: "d-flex flex-column" }, [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("No members found"),
                  ]),
                  _c(
                    "span",
                    [
                      _vm._v(" Currently this space has no users. You can "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            dark: "",
                            color: "info",
                            outlined: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("goToInvite", { value: true })
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("add"),
                          ]),
                          _vm._v(" Invite "),
                        ],
                        1
                      ),
                      _vm._v(" new members. "),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm.fetchingSpaceUsers
      ? _c(
          "div",
          _vm._l(
            [
              { id: "c1", opacity: 1 },
              { id: "c2", opacity: 0.75 },
              { id: "c3", opacity: 0.5 },
            ],
            function (item) {
              return _c("div", { key: item.id, staticClass: "my-6" }, [
                _c(
                  "div",
                  { style: { opacity: item.opacity } },
                  [
                    _c("v-skeleton-loader", {
                      staticClass: "mx-auto",
                      attrs: { type: "list-item-avatar-two-line" },
                    }),
                  ],
                  1
                ),
              ])
            }
          ),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }